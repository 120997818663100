@charset "UTF-8";
/**
 * SCSS for Sygnal Webflow Util HTML library
 */
/**
 * @use: This is the modern replacement for @import. 
 * Instead of dumping all the imported styles into the global scope, @use treats each imported file as a 
 * separate module with its own private scope. This means that variables, mixins, and functions from 
 * the imported file have to be accessed with a namespace based on the filename. 
 * This helps to prevent naming conflicts and makes the code easier to understand.
 */
@import url("https://fonts.googleapis.com/css2?family=Redacted&display=swap");
/**
 * SA5 Nested lists
 */
.w-richtext ul > ul, .w-richtext ol > ol {
  margin-top: 0;
}
.w-richtext ul ul, .w-richtext ol ol {
  margin-top: 0.2rem;
}

html:not([data-wf-mode=editor]) [wfu-list-theme=default] ol.wfu-list-level-1 {
  list-style-type: decimal;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] ol.wfu-list-level-2 {
  list-style-type: lower-alpha;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] ol.wfu-list-level-3 {
  list-style-type: lower-roman;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] ol.wfu-list-level-4 {
  list-style-type: disc;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] ol.wfu-list-level-5 {
  list-style-type: circle;
}

html:not([data-wf-mode=editor]) [wfu-list-theme=default] li:has(> span.wfu-pro) {
  list-style-type: none;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] li:has(> span.wfu-pro)::marker {
  content: "✔  ";
  color: green;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] span.wfu-pro {
  color: green;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] li:has(> span.wfu-con) {
  list-style-type: none;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] li:has(> span.wfu-con)::marker {
  content: "✖  ";
  color: red;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=default] span.wfu-con {
  color: red;
}

html:not([data-wf-mode=editor]) [wfu-list-theme=modern] ol.wfu-list-level-1 {
  list-style-type: decimal-leading-zero;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] ol.wfu-list-level-2 {
  list-style-type: lower-alpha;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] ol.wfu-list-level-3 {
  list-style-type: lower-roman;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] ol.wfu-list-level-4 {
  list-style-type: disc;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] ol.wfu-list-level-5 {
  list-style-type: circle;
}

html:not([data-wf-mode=editor]) [wfu-list-theme=modern] li:has(> span.wfu-pro) {
  list-style-type: none;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] li:has(> span.wfu-pro)::marker {
  content: "↑  ";
  color: green;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] span.wfu-pro {
  color: green;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] li:has(> span.wfu-con) {
  list-style-type: none;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] li:has(> span.wfu-con)::marker {
  content: "↓  ";
  color: red;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=modern] span.wfu-con {
  color: red;
}

html:not([data-wf-mode=editor]) [wfu-list-theme=fun] ol.wfu-list-level-1 {
  list-style-type: decimal-leading-zero;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=fun] ol.wfu-list-level-2 {
  list-style-type: lower-alpha;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=fun] ol.wfu-list-level-3 {
  list-style-type: lower-roman;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=fun] ol.wfu-list-level-4 {
  list-style-type: disc;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=fun] ol.wfu-list-level-5 {
  list-style-type: circle;
}

html:not([data-wf-mode=editor]) [wfu-list-theme=fun] li:has(> span.wfu-pro) {
  list-style-type: none;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=fun] li:has(> span.wfu-pro)::marker {
  content: "👍   ";
}
html:not([data-wf-mode=editor]) [wfu-list-theme=fun] li:has(> span.wfu-con) {
  list-style-type: none;
}
html:not([data-wf-mode=editor]) [wfu-list-theme=fun] li:has(> span.wfu-con)::marker {
  content: "👎   ";
}
/*
 * Editor mode detection 
 */
/*

html:not([data-wf-mode="editor"]) .w-richtext ul ul, .w-richtext ol ol {
    margin-top: 0.5rem;
}

html:not([data-wf-mode="editor"]) ol .wfu-list-level-2 {
    list-style-type: lower-latin;
}

html:not([data-wf-mode="editor"]) ol .wfu-list-level-3 {
    list-style-type: lower-roman;
}

html:not([data-wf-mode="editor"]) .wfu-pro {
    list-style-type: none;
    color: green;
}

    html:not([data-wf-mode="editor"]) .wfu-pro::marker {
        content: "☑  ";
    }

html:not([data-wf-mode="editor"]) .wfu-con {
    list-style-type: none;
    color: red;
}

    html:not([data-wf-mode="editor"]) .wfu-con::marker {
        content: "☒  ";
    }


*/
/**
 * SCSS Limit collection list items, at breakpoint levels
 */
@media screen {
  [wfu-limit-items="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (min-width: 1920px) {
  [wfu-limit-items-1920="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1920="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  [wfu-limit-items-1440="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1440="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1439px) {
  [wfu-limit-items-1280="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-1280="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  [wfu-limit-items-d-max="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d-max="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (min-width: 992px) and (max-width: 1279px) {
  [wfu-limit-items-d="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-d="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  [wfu-limit-items-t="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-t="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  [wfu-limit-items-l="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-l="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
@media screen and (max-width: 479px) {
  [wfu-limit-items-p="1"] > div:nth-child(n+2):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="2"] > div:nth-child(n+3):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="3"] > div:nth-child(n+4):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="4"] > div:nth-child(n+5):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="5"] > div:nth-child(n+6):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="6"] > div:nth-child(n+7):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="7"] > div:nth-child(n+8):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="8"] > div:nth-child(n+9):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="9"] > div:nth-child(n+10):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="10"] > div:nth-child(n+11):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="11"] > div:nth-child(n+12):nth-child(-n+1000) {
    display: none;
  }
  [wfu-limit-items-p="12"] > div:nth-child(n+13):nth-child(-n+1000) {
    display: none;
  }
}
[wfu-decode] {
  display: none;
}

/*
 * Text trucation w/ ellipsis support
 */
[wfu-truncate] {
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

[wfu-truncate="2"] {
  -webkit-line-clamp: 2;
}

[wfu-truncate="3"] {
  -webkit-line-clamp: 3;
}

[wfu-truncate="4"] {
  -webkit-line-clamp: 4;
}

[wfu-truncate="5"] {
  -webkit-line-clamp: 5;
}

/*
[wfu-truncate]::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}
*/
/*
 * Filtered elements
 * hide by default, until filter statis is determined
 */
*[wfu-filter] {
  display: none;
}

*[wfu-filter-func] {
  display: none;
}

[wfu-sort] {
  display: none;
}

/*
 * Skeleton loader
 * apply skeleton loader effect to elements targeted for parsing by WFU.
 */
.w-richtext[wfu-lists-loading] li {
  font-family: "Redacted", cursive;
  color: black;
  display: inline-block;
  animation: skeleton-loading 1s linear infinite alternate;
}
.w-richtext[wfu-lists-loading] li em {
  font-style: normal;
}
.w-richtext[wfu-lists-loading] li a {
  color: inherit;
}

@keyframes skeleton-loading {
  0% {
    color: hsl(200, 20%, 80%);
  }
  100% {
    color: hsl(200, 20%, 95%);
  }
}
/*
 * Hide elements on spec 
 */
[wfu-hide=empty-collection-list]:not(:has(.w-dyn-item)) {
  display: none;
}

/* test */
/*
 * HTML Table Themes 
 */
[wfu-theme=default] table {
  border-collapse: collapse;
  margin-top: 10px;
}
[wfu-theme=default] table tr td {
  padding: 0.2rem 1rem;
  border: 1px solid white;
  vertical-align: top;
  margin-bottom: 5px;
}
[wfu-theme=default] table tr td p {
  line-height: 22px;
  font-size: 14px;
}
[wfu-theme=default] table tr td ul {
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 0.2rem;
}
[wfu-theme=default] table tr td ul li::marker {
  content: "⁃";
  color: #2e9dff;
}
[wfu-theme=default] table tr td ul li {
  line-height: 22px;
  font-size: 14px;
  padding-left: 1ch;
}
[wfu-theme=default] table tr:nth-child(1) {
  font-weight: 800;
  background-color: rgba(255, 255, 255, 0.2);
}